var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MainDrawerBtn",
    { attrs: { title: "Show Push To Talk", svgPrepend: "walkie-talkie" } },
    [
      _c("v-switch", {
        staticClass: "ml-auto shrink",
        attrs: { color: "#8680FF" },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }