






import { computed, defineComponent } from "@vue/composition-api"
import MainDrawerBtn from "@/components/drawers/main/MainDrawerBtn.vue"
import useStore from "@/use/useStore"
import useIdentities from "@/use/useIdentities"
import { db } from "@/firebase"

export default defineComponent({
  name: "PushToTalkSwitch",
  components: { MainDrawerBtn },
  setup() {
    const { store } = useStore()
    const active = computed(() => !store.state.game?.pushToTalkHidden)

    const { orgID, gameID } = useIdentities()

    const pushToTalkRef = db
      .auxiliary()
      .ref(`org/${orgID.value}/games/${gameID.value}/pushToTalkHidden`)

    function show() {
      pushToTalkRef.remove()
    }

    function hide() {
      pushToTalkRef.set(true)
    }

    const value = computed({
      get: () => active.value,
      set: value => (value ? show() : hide())
    })

    return { value }
  }
})
